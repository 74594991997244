<template>
  <div>
    <v-card
      @drop.prevent="drop($event)"
      @dragover.prevent="dragoverData = true"
      @dragenter.prevent="dragoverData = true"
      @dragleave.prevent="dragoverData = false"
      elevation="0"
    >
      <v-card-title>
        <v-row>
          <v-col class="text-center">{{ title }}</v-col>
        </v-row>
      </v-card-title>
      <br>
      <v-card-text>
        <v-row class="d-flex justify-center align-center">
          <div
            class="p-12"
          >
            <input
              type="file" multiple :name="`fields[${propId}][]`"
              :id="propId"
              class="d-none absolute" @input="onChange" ref="inputRef"
            />

            <label
              style="border: 3px dashed lightgray" :for="propId"
              class="block d-flex flex-column cursor-pointer px-0 px-md-10"
            >
              <v-icon style="font-size: 5rem">mdi-upload</v-icon>
              <p class="text-center text-h6">Drag & drop files or&nbsp; <span
                style="cursor: pointer" class="text-decoration-underline"
              >Browse</span> </p>
            </label>
            <br>
            <div v-if="filesToUpload.length">
              <v-row class="px-3" v-for="(file,index) in filesToUpload">
                  <v-col
                    class="mt-1 py-1 d-flex flex-row justify-space-between"
                    style="border: 1px solid #11AF22" cols="12"
                  >
                    <span
                      style="font-size: 12px"
                      :key="index + 999"
                    >
                      {{ file.original_file_name }}
                    </span>
                    <v-btn x-small icon @click="removeFileFromFileList(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
              </v-row>
            </div>
          </div>
        </v-row>
        <br>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="save-files-btn"
          color="primary"
          block
          :loading="loading"
          v-if="filesToUpload.length > 0"
          @click="saveData"
        >
          Upload files
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="errorMessage"
      width="500"
    >
      <v-card class="pa-16" style="background-color: #263B4F">
        <h2 style="color: #FFFFFF" class="text-center mb-7">The chosen file is over the File Size limit of 25Mb.
          Please
          try another file.</h2>
        <v-progress-linear
          absolute color="red"
          :v-model="errProgressBarValue"
          :buffer-value="bufferValue"
        >
        </v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "UploadDocument",
  props: {
    title: {
      type: String,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean
    },
    propId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      dragoverData: false,
      filesToUpload: [],
      errorMessage: false,
      errProgressBarValue: 0,
      bufferValue: 0,
      interval: "",
    };
  },
  methods: {
    removeFileFromFileList(index) {
      const dt = new DataTransfer();
      const files = this.$refs.inputRef.files;
      this.filesToUpload.splice(index, 1);

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (index !== i)
          dt.items.add(file);
      }

      this.$refs.inputRef.files = dt.files;
    },
    saveData() {
      this.$emit("upload", this.filesToUpload);
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    onChange() {
      for (let i = 0; i < this.$refs.inputRef.files.length; i++) {
        if (this.$refs.inputRef.files[i].size < 25000000) {
          let uploadedFile = this.$refs.inputRef.files[i];
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.currentUser.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          };
          this.filesToUpload.push(newFile);

        } else {
          const removeMessageAfter = 2500;
          this.errorMessage = true;
          this.startBuffer();
          setTimeout(() => {
            this.errorMessage = false;
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter);
        }
      }
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.errProgressBarValue += 2;
        this.bufferValue += 2;
      }, 50);
    },
  },
};
</script>

<style scoped>

</style>