import $axios from '../plugins/axios'

class AppealsService {
  getClientAppeals(clientId) {
    return $axios.get(`/clients/${clientId}/appeals`).then(res => res.data)
  }

  updateDenialLetter(denialId, payload) {
    return $axios.patch(`/appeals/denial-letter/${denialId}`, payload).then(res => res.data)
  }

  getAppealById(appealId) {
    return $axios.get(`/appeals/${appealId}`).then(res => res.data)
  }

  getAppealsReport(filter) {
    return $axios.get(`/reports/appeals/${filter}`).then(res => res.data)
  }

  getAppealLetter(id,letter) {
    return $axios.get(`/appeals/${id}/letters/${letter}`).then(res => res.data)
  }
  generateAppealLetter(id,data) {
    return $axios.post(`/appeals/${id}/cover_letter`,data).then(res => res.data)
  }

  getNewAppeals() {
    return $axios.get(`/reports/denied_letters`).then(res => res.data)
  }
  getMandates(state) {
    return $axios.get(`/mandates/state?q=${state}`).then(res => res.data)
  }

  updateAppeal(appealId, payload) {
    return $axios.patch(`/appeals/${appealId}`, payload).then(res => res.data)
  }
  litigationHold(id,payload) {
    return $axios.patch(`/denial-letter/${id}/hold`, payload).then(res => res.data)
  }
}

export default new AppealsService()
